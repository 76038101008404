import React, { Component } from 'react';
import backgroundImg from '../Images/ChineseVillage.jpg';
import { Container } from 'reactstrap';

function IntroBoard(props) {

    return (
        <div
            style={{
                position: 'relative',
                textAlign: 'center',
                display: 'flex'
            }}
        >
            <img
                src={backgroundImg}
                style={{
                    width: '100vw',
                    zIndex: '1',
                }}
            />
            <h1
                style={{
                    position: 'absolute',
                    color: 'white',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '15rem',
                    fontSize: '80px',
                    zIndex: '2',
                }}
            >
                HalfRift Game
            </h1>
            <h1
                style={{
                    position: 'absolute',
                    color: 'white',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '22rem',
                    fontSize: '20px',
                    zIndex: '2',
                }}
            >
                Game Studio under HalfRift Inc
            </h1>
        </div>
    );
  
}

export default IntroBoard;
