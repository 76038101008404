import React, { Component } from 'react';
import CharacterA from '../Images/CharacterA.png';
import SceneA1 from '../Images/SceneA1.png';
import SceneA2 from '../Images/SceneA2.png';
import CharacterB2 from '../Images/CharacterB2.png';
import CharacterB3 from '../Images/CharacterB3.png';
import CharacterB4 from '../Images/CharacterB4.png';
import WeaponA from '../Images/WeaponA.png';

export default function ProjectBoard(props) {
    return (
        <div
            style={{
                position: 'relative',
                textAlign: 'center',
                display: 'flex',
                backgroundColor: 'antiquewhite',
                color: '#354757',
            }}
        >
            <div style={{
                width: '100vw',
                marginTop: '5rem',
                height: '50rem'
            }}>
                <h1>Current Project</h1>
                <h1
                    style={{
                        marginTop: '2rem',
                    }}
                >
                    ShatteredSteorra
                </h1>
                <h1
                    style={{
                        marginTop: '2rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        fontSize: '20px',
                        width:'25rem'
                    }}
                >
                    Hack and slash anime style Single player ARPG game WIP. Plan to release on Steam PC late 2022. Future plan includes migration to mobile platform. 
                </h1>
            </div>
            <img
                src={CharacterA}
                style={{
                    width: '25rem',
                    position: 'absolute',
                    marginLeft: '5rem',
                    marginTop: '5rem'
                }}
            />
            <img
                src={SceneA1}
                style={{
                    width: '25rem',
                    position: 'absolute',
                    marginLeft: '70%',
                    marginTop: '7rem'
                }}
            />
            <img
                src={SceneA2}
                style={{
                    width: '25rem',
                    position: 'absolute',
                    marginLeft: '70%',
                    marginTop: '20rem'
                }}
            />
            <img
                src={WeaponA}
                style={{
                    width: '25rem',
                    position: 'absolute',
                    marginLeft: '65%',
                    marginTop: '33rem'
                }}
            />
            <img
                src={CharacterB2}
                style={{
                    width: '15rem',
                    position: 'absolute',
                    marginLeft: '10rem',
                    marginTop: '29rem'
                }}
            />
            <img
                src={CharacterB3}
                style={{
                    width: '15rem',
                    position: 'absolute',
                    marginLeft: '25rem',
                    marginTop: '30rem'
                }}
            />
            <img
                src={CharacterB4}
                style={{
                    width: '18rem',
                    position: 'absolute',
                    marginLeft: '39rem',
                    marginTop: '24rem'
                }}
            />
        </div>
    );
}
