import React, { Component } from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button, Container,
    Row, Col
} from 'reactstrap';

export default function Footer(props) {
    return (
        <div style={{ backgroundColor: 'rgb(21 31 40)'}}>
            <Card style={{ height: '5rem', backgroundColor: 'rgb(21 31 40)'}}>
                <CardBody>
                    <Container>
                        <Row>
                            <Col>
                                <CardTitle tag="h5" style={{color:'white'}}>HalfRift Inc</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">question@halfrift.awsapps.com</CardSubtitle>
                            </Col>
                            <Col>
                            </Col>
                            <Col>
                                <CardText style={{color:'white'}}>Copyright &copy; HalfRift All Rights Reserved.</CardText>
                            </Col>
                        </Row>
                    </Container>
                </CardBody>
            </Card>
        </div>
    );
}
