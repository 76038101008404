import React, { Component } from 'react';
import patternImage from '../Images/productCurvyLines.png';
import {
    Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';

function IntroDescription(props) {

    return (
        <div
            style={{
                position: 'relative',
                width: '100vw',
                height: '50rem',
                backgroundColor: 'rgb(239 219 216)',
                overflow: 'hidden'
            }}
        >
            <img
                src={patternImage}
                style={{
                    width: '100%',
                    zIndex: '0',
                    position: 'absolute'
                }}
            />
            <Container
                style={{
                    marginTop:'15rem',
                }}
            >
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Game Development</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Just try to make fun games</CardSubtitle>
                                <CardText>The studio is founded by people who have their own full-time work while supporting studio projects to realize their childhood dream.
                                    Ever had a moment thinking 'what if ...' while playing a game, now it is time to make them come true.</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col
                        style={{
                            marginTop:'5rem'
                        }}
                    >
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Community</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Community contribution and support</CardSubtitle>
                                <CardText>Thanks for the growing indie game dev community and powerful toolsets, it makes indie game development more accessible than ever.
                                    While taking advantages of those new goods, we are constantly participating and contributing back to the community,
                                    supporting other developers while receiving help at the meanwhile</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col
                        style={{
                            marginTop: '10rem'
                        }}
                    >
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Game Aesthetics</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Small but aesthetic</CardSubtitle>
                                <CardText>As a start-up game studio, even though we are not able to afford tripple A size game development, that doesn't mean we can't deliver tripple A game aethetics.
                                    We believe that no matter how big the game is, the most important part is to polish every single part of the game and deliver most immersive experience as possible.</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default IntroDescription;
