import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import IntroBoard from './IntroBoard';
import IntroDescription from './IntroDescription';
import ProjectBoard from './ProjectBoard';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
        <Container style={{ display: 'table-row'}}>
            <Row>
                <IntroBoard />
            </Row>
            <Row>
                <IntroDescription />
            </Row>
            <Row>
                <ProjectBoard />
            </Row>
        </Container>
    );
  }
}
